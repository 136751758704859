<template>
  <v-card
    v-if="
      relatorio.Step40 ||
      relatorio.Step41 ||
      relatorio.Step42 ||
      relatorio.Step43 ||
      relatorio.Step44 ||
      relatorio.Step45 ||
      relatorio.Step4rec
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Procedimentos corretos para limpeza de ordenha</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.Step40 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente para remover o excesso residual do leite (pré enxague).'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step40 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente para remover o excesso residual do leite (pré enxague).'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step41 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura de 80º C com detergente alcalino clorado durante 10 minutos.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step41 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura de 80º C com detergente alcalino clorado durante 10 minutos.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step42 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente para remover o excesso residual do detergente alcalino (enxague).'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step42 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente para remover o excesso residual do detergente alcalino (enxague).'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step43 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente com detergente ácido, no mínimo 03 (três) vezes por semana.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step43 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente com detergente ácido, no mínimo 03 (três) vezes por semana.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step44 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step44 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Circula água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step45 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Faz sanitização da ordenhadeira 30 (trinta) minutos antes da ordenha.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step45 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Faz sanitização da ordenhadeira 30 (trinta) minutos antes da ordenha.'
            }}
          </span>
        </v-col>

        <v-col v-if="relatorio.Step4rec != ''" cols="12" class="col-bordered">
          <span class="key-title">
            {{ 'Recomendações:' }}
          </span>
          <v-col cols="12">
            <span class="value-title">
              {{ relatorio.Step4rec }}
            </span>
            <br />
          </v-col>
        </v-col>
        <span class="pa-3"></span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
