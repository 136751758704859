<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Principais Indicadores</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Última Análise De CPP: </span>
          <span class="value-title">
            {{
              relatorio.Ultimaanalisedecpp
                ? relatorio.Ultimaanalisedecpp
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Média Geométrica De CPP:</span>
          <span class="value-title">
            {{
              relatorio.Mediageometricadecpp
                ? relatorio.Mediageometricadecpp
                : 'Não informado'
            }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Última análise de CCS: </span>
          <span class="value-title">{{
            relatorio.Ultimanalisedeccs
              ? relatorio.Ultimanalisedeccs
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="6" xl="6" class="col-bordered">
          <span class="key-title">Média geométrica de CCS: </span>
          <span class="value-title">{{
            relatorio.Mediageometricadeccs
              ? relatorio.Mediageometricadeccs
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Temperatura do leite: </span>
          <span class="value-title">{{
            relatorio.Temperaturadoleite
              ? relatorio.Temperaturadoleite
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Higiene das instalações: </span>
          <span v-if="relatorio.HIOtimo" class="value-title">Ótimo</span>
          <span v-if="relatorio.HIBom" class="value-title">Bom</span>
          <span v-if="relatorio.HIRuim" class="value-title">Ruim</span>
        </v-col>
        <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
          <span class="key-title">Sanidade do rebanho: </span>
          <span v-if="relatorio.SAOtimo" class="value-title">Ótimo</span>
          <span v-if="relatorio.SABom" class="value-title">Bom</span>
          <span v-if="relatorio.SARuim" class="value-title">Ruim</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {}
  },

  methods: { convertNumberBR },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
