<template>
  <v-card
    v-if="
      relatorio.Step50 ||
      relatorio.Step51 ||
      relatorio.Step52 ||
      relatorio.Step53 ||
      relatorio.Step54 ||
      relatorio.Step55 ||
      relatorio.Step5rec
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Procedimentos corretos para limpeza do tanque</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.Step50 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente para remover o excesso residual do leite.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step50 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente para remover o excesso residual do leite.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step51 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente com detergente alcalino (espumante).'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step51 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente com detergente alcalino (espumante).'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step52 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente (enxague).'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step52 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente (enxague).'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step53 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente com ácido, no mínimo 03 (três) vezes por semana.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step53 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente com ácido, no mínimo 03 (três) vezes por semana.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step54 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step54 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step55 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Faz Sanitização com produtos adequados no tanque.' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step55 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Faz Sanitização com produtos adequados no tanque.' }}
          </span>
        </v-col>

        <v-col v-if="relatorio.Step5rec != ''" cols="12" class="col-bordered">
          <span class="key-title">
            {{ 'Recomendações:' }}
          </span>
          <v-col cols="12">
            <span class="value-title">
              {{ relatorio.Step5rec }}
            </span>
            <br />
          </v-col>
        </v-col>
        <span class="pa-3"></span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
