<template>
  <v-card
    v-if="
      relatorio.Step31 ||
      relatorio.Step32 ||
      relatorio.Step33 ||
      relatorio.Step34 ||
      relatorio.Step35 ||
      relatorio.Step36 ||
      relatorio.Step37 ||
      relatorio.Step38 ||
      relatorio.Step39 ||
      relatorio.Step310 ||
      relatorio.Step311 ||
      relatorio.Step312 ||
      relatorio.Step313 ||
      relatorio.Step314 ||
      relatorio.Step3rec
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Orientações com objetivo de melhoria da qualidade e produção do
      leite</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.Step31 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de local sombreado e com acesso a água limpa para os animais.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step31 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de local sombreado e com acesso a água limpa para os animais.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step32 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de local específico para armazenamento de rações, insumos e medicamentos.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step32 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de local específico para armazenamento de rações, insumos e medicamentos.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step33 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de sala própria e adequada para o resfriador, com ponto de acesso a água limpa.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step33 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de sala própria e adequada para o resfriador, com ponto de acesso a água limpa.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step34 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Dispõe e usa água de boa qualidade e caixa d água com dosador de cloro.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step34 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Dispõe e usa água de boa qualidade e caixa d água com dosador de cloro.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step35 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de equipamentos e utensílios apropriados e higienizados.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step35 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Dispõe de equipamentos e utensílios apropriados e higienizados.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step36 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Faz linha de ordenha.' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step36 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Faz linha de ordenha.' }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step37 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Mantém a higienização e limpeza das instalações e imediações do ambiente.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step37 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Mantém a higienização e limpeza das instalações e imediações do ambiente.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step38 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Realiza controle de pragas nos setores de instalações de armazenamento e imediações.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step38 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Realiza controle de pragas nos setores de instalações de armazenamento e imediações.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step39 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Retira da produção animais que apresentam alterações no estado de saúde.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step39 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Retira da produção animais que apresentam alterações no estado de saúde.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step310 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Descarta o leite de animais com mastite ou outro tipo de doença, até completar a cura e o período de carência.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step310 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Descarta o leite de animais com mastite ou outro tipo de doença, até completar a cura e o período de carência.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step311 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Lava os tetos quando necessário e elimina os três primeiros jatos em caneca de fundo preto.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step311 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Lava os tetos quando necessário e elimina os três primeiros jatos em caneca de fundo preto.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step312 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Faz o teste de CMT (raquete) identificando animais com mastite subclínica.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step312 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Faz o teste de CMT (raquete) identificando animais com mastite subclínica.'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step313 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Faz pré dipping e pós dipping.' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step313 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Faz pré dipping e pós dipping.' }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.Step314 == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Realiza manutenção de ordenha (troca de mangueiras, teteiras...) conforme recomendações do fabricante.'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Step314 != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Realiza manutenção de ordenha (troca de mangueiras, teteiras...) conforme recomendações do fabricante.'
            }}
          </span>
        </v-col>

        <v-col v-if="relatorio.Step3rec != ''" cols="12" class="col-bordered">
          <span class="key-title">
            {{ 'Recomendações:' }}
          </span>
          <v-col cols="12">
            <span class="value-title">
              {{ relatorio.Step3rec }}
            </span>
            <br />
          </v-col>
        </v-col>
        <span class="pa-3"></span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
