<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório de visita Bonolat </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGeraisBonolat :relatorio="relatorio" />
      <IndicadoresQualidadeBonolat :relatorio="relatorio" />
      <MelhoriaLeiteBonolat :relatorio="relatorio" />
      <LimpezaOrdenhaBonolat :relatorio="relatorio" />
      <LimpezaTanqueBonolat :relatorio="relatorio" />
      <ProximaVisitaBonolat :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn color="primary" @click="exportPDF(relatorio)">
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFBonolat'
import { exportReport } from '../../../../utils/report'
import IndicadoresQualidadeBonolat from './IndicadoresQualidadeBonolat'
import InformacoesGeraisBonolat from './InformacoesGeraisBonolat'
import LimpezaOrdenhaBonolat from './LimpezaOrdenhaBonolat'
import LimpezaTanqueBonolat from './LimpezaTanqueBonolat'
import MelhoriaLeiteBonolat from './MelhoriaLeiteBonolat'
import ProximaVisitaBonolat from './ProximaVisitaBonolat'

export default {
  components: {
    LimpezaOrdenhaBonolat,
    LimpezaTanqueBonolat,
    IndicadoresQualidadeBonolat,
    InformacoesGeraisBonolat,
    MelhoriaLeiteBonolat,
    ProximaVisitaBonolat,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/bonolat`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    exportPDF,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },
    async exportRel(relatorio) {
      if (!relatorio) relatorio = this.relatorio

      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
      })
    },
    alert() {
      this.Swal.fire({
        title: 'Atenção',
        text: 'Em Desenvolvimento!!!',
        icon: 'warning',
      })
    },
  },
}
</script>
