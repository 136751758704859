import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')

  return cursor + smallBoxHeight
}

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório de visita Bonolat')

  doc.text(
    'Relatório de visita Bonolat',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Última Análise De CPP:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.Ultimaanalisedecpp
      ? relatorio.Ultimaanalisedecpp
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 2, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Média Geométrica De CPP:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.Mediageometricadecpp
      ? relatorio.Mediageometricadecpp
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Última análise de CCS:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.Ultimanalisedeccs ? relatorio.Ultimanalisedeccs : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Média geométrica de CCS:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.Mediageometricadeccs
      ? relatorio.Mediageometricadeccs
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F') //superior

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F') //esquerda

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F') //direita

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F') //inferior

  doc.text(
    'Temperatura do leite:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.Temperaturadoleite
      ? relatorio.Temperaturadoleite
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Higiene das instalações:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  if (relatorio.HIOtimo) {
    doc.text(
      'Ótimo',
      startX + tableLimit / 3 + smallGutterX,
      startY + boxHeight * 3 + gutterY,
    )
  }
  if (relatorio.HIBom) {
    doc.text(
      'Bom',
      startX + tableLimit / 3 + smallGutterX,
      startY + boxHeight * 3 + gutterY,
    )
  }
  if (relatorio.HIRuim) {
    doc.text(
      'Ruim',
      startX + tableLimit / 3 + smallGutterX,
      startY + boxHeight * 3 + gutterY,
    )
  }

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Sanidade do rebanho:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  if (relatorio.SAOtimo) {
    doc.text(
      'Ótimo',
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      startY + boxHeight * 3 + gutterY,
    )
  }

  if (relatorio.SABom) {
    doc.text(
      'Bom',
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      startY + boxHeight * 3 + gutterY,
    )
  }

  if (relatorio.SARuim) {
    doc.text(
      'Ruim',
      startX + tableLimit - tableLimit / 3 + smallGutterX,
      startY + boxHeight * 3 + gutterY,
    )
  }
}

function melhoriaLeite(doc, relatorio, config, cursor) {
  const {
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    tableLimit,
    line,
  } = config

  if (
    relatorio.Step31 ||
    relatorio.Step32 ||
    relatorio.Step33 ||
    relatorio.Step34 ||
    relatorio.Step35 ||
    relatorio.Step36 ||
    relatorio.Step37 ||
    relatorio.Step38 ||
    relatorio.Step39 ||
    relatorio.Step310 ||
    relatorio.Step311 ||
    relatorio.Step312 ||
    relatorio.Step313 ||
    relatorio.Step314 ||
    relatorio.Step3rec
  ) {
    cursor += config.boxHeight * 14

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text(
        'Orientações com objetivo de melhoria da qualidade e produção do leite',
        startX,
        cursor,
      )
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.Step31 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Dispõe de local sombreado e com acesso a água limpa para os animais.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step31 != '') {
      renderCheckbox(
        doc,
        '(X) Dispõe de local sombreado e com acesso a água limpa para os animais.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step32 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Dispõe de local específico para armazenamento de rações, insumos e medicamentos.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step32 != '') {
      renderCheckbox(
        doc,
        '(X) Dispõe de local específico para armazenamento de rações, insumos e medicamentos.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step33 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Dispõe de sala própria e adequada para o resfriador, com ponto de acesso a água limpa.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step33 != '') {
      renderCheckbox(
        doc,
        '(X) Dispõe de sala própria e adequada para o resfriador, com ponto de acesso a água limpa.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step34 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Dispõe e usa água de boa qualidade e caixa d água com dosador de cloro.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step34 != '') {
      renderCheckbox(
        doc,
        '(X) Dispõe e usa água de boa qualidade e caixa d água com dosador de cloro.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step35 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Dispõe de equipamentos e utensílios apropriados e higienizados.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step35 != '') {
      renderCheckbox(
        doc,
        '(X) Dispõe de equipamentos e utensílios apropriados e higienizados.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step36 == 'confirm') {
      renderCheckbox(doc, '(V) Faz linha de ordenha.', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step36 != '') {
      renderCheckbox(doc, '(X) Faz linha de ordenha.', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step37 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Mantém a higienização e limpeza das instalações e imediações do ambiente.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step37 != '') {
      renderCheckbox(
        doc,
        '(X) Mantém a higienização e limpeza das instalações e imediações do ambiente.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step38 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Realiza controle de pragas nos setores de instalações de armazenamento e imediações.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step38 != '') {
      renderCheckbox(
        doc,
        '(X) Realiza controle de pragas nos setores de instalações de armazenamento e imediações.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step39 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Retira da produção animais que apresentam alterações no estado de saúde.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step39 != '') {
      renderCheckbox(
        doc,
        '(X) Retira da produção animais que apresentam alterações no estado de saúde.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step310 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Descarta o leite de animais com mastite ou outro tipo de doença, até completar a cura e o período de carência.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step310 != '') {
      renderCheckbox(
        doc,
        '(X) Descarta o leite de animais com mastite ou outro tipo de doença, até completar a cura e o período de carência.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step311 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Lava os tetos quando necessário e elimina os três primeiros jatos em caneca de fundo preto.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step311 != '') {
      renderCheckbox(
        doc,
        '(X) Lava os tetos quando necessário e elimina os três primeiros jatos em caneca de fundo preto.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step312 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Faz o teste de CMT (raquete) identificando animais com mastite subclínica.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step312 != '') {
      renderCheckbox(
        doc,
        '(X) Faz o teste de CMT (raquete) identificando animais com mastite subclínica.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step313 == 'confirm') {
      renderCheckbox(doc, '(V) Faz pré dipping e pós dipping.', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step313 != '') {
      renderCheckbox(doc, '(X) Faz pré dipping e pós dipping.', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step314 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Realiza manutenção de ordenha (troca de mangueiras, teteiras...) conforme recomendações do fabricante.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step314 != '') {
      renderCheckbox(
        doc,
        '(X) Realiza manutenção de ordenha (troca de mangueiras, teteiras...) conforme recomendações do fabricante.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step3rec) {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight
      renderCheckboxList(doc, relatorio.Step3rec, cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.text(
      'Considere (V) para conformidade e (X) para não conformidade.',
      startX,
      cursor + smallBoxHeight - 5,
    )
  }

  cursor += smallBoxHeight

  return cursor
}

function limpezaOrdenha(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.Step40 ||
    relatorio.Step41 ||
    relatorio.Step42 ||
    relatorio.Step43 ||
    relatorio.Step44 ||
    relatorio.Step45 ||
    relatorio.Step4rec
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 100 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Procedimentos corretos para limpeza de ordenha', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.Step40 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Circula água limpa a temperatura ambiente para remover o excesso residual do leite (pré enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step40 != '') {
      renderCheckbox(
        doc,
        '(X) Circula água limpa a temperatura ambiente para remover o excesso residual do leite (pré enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step41 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Circula água limpa a temperatura de 80º C com detergente alcalino clorado durante 10 minutos.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step41 != '') {
      renderCheckbox(
        doc,
        '(X) Circula água limpa a temperatura de 80º C com detergente alcalino clorado durante 10 minutos.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step42 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Circula água limpa a temperatura ambiente para remover o excesso residual do detergente alcalino (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step42 != '') {
      renderCheckbox(
        doc,
        '(X) Circula água limpa a temperatura ambiente para remover o excesso residual do detergente alcalino (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step43 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Circula água limpa a temperatura ambiente com detergente ácido, no mínimo 03 (três) vezes por semana.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step43 != '') {
      renderCheckbox(
        doc,
        '(X) Circula água limpa a temperatura ambiente com detergente ácido, no mínimo 03 (três) vezes por semana.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step44 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Circula água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step44 != '') {
      renderCheckbox(
        doc,
        '(X) Circula água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step45 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Faz sanitização da ordenhadeira 30 (trinta) minutos antes da ordenha.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step45 != '') {
      renderCheckbox(
        doc,
        '(X) Faz sanitização da ordenhadeira 30 (trinta) minutos antes da ordenha.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step4rec) {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight
      renderCheckboxList(doc, relatorio.Step4rec, cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.text(
      'Considere (V) para conformidade e (X) para não conformidade.',
      startX,
      cursor + smallBoxHeight - 5,
    )
    cursor += smallBoxHeight
  }
  return cursor
}

function limpezaTanque(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.Step50 ||
    relatorio.Step51 ||
    relatorio.Step52 ||
    relatorio.Step53 ||
    relatorio.Step54 ||
    relatorio.Step55 ||
    relatorio.Step5rec
  ) {
    cursor += config.boxHeight

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Procedimentos corretos para limpeza do tanque', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.Step50 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Utiliza água limpa a temperatura ambiente para remover o excesso residual do leite.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step50 != '') {
      renderCheckbox(
        doc,
        '(X) Utiliza água limpa a temperatura ambiente para remover o excesso residual do leite.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step51 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Utiliza água limpa a temperatura ambiente com detergente alcalino (espumante).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step51 != '') {
      renderCheckbox(
        doc,
        '(X) Utiliza água limpa a temperatura ambiente com detergente alcalino (espumante).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step52 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step52 != '') {
      renderCheckbox(
        doc,
        '(X) Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step53 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Utiliza água limpa a temperatura ambiente com ácido, no mínimo 03 (três) vezes por semana.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step53 != '') {
      renderCheckbox(
        doc,
        '(X) Utiliza água limpa a temperatura ambiente com ácido, no mínimo 03 (três) vezes por semana.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step54 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step54 != '') {
      renderCheckbox(
        doc,
        '(X) Utiliza água limpa a temperatura ambiente para remover o excesso residual do detergente ácido (enxague).',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step55 == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Faz Sanitização com produtos adequados no tanque.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Step55 != '') {
      renderCheckbox(
        doc,
        '(X) Faz Sanitização com produtos adequados no tanque.',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }

    if (relatorio.Step5rec) {
      doc.setFont('helvetica', 'bold')
      renderCheckboxList(doc, ' • Recomendação:', cursor, config)
      doc.setFont('helvetica', 'normal')
      cursor += smallBoxHeight
      renderCheckboxList(doc, relatorio.Step5rec, cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.text(
      'Considere (V) para conformidade e (X) para não conformidade.',
      startX,
      cursor + smallBoxHeight - 5,
    )
    if (cursor + boxHeight * 11 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
  }

  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight + 30

  if (cursor + boxHeight * 10 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  const proximaVisita = relatorio.DataProximaVisita
    ? relatorio.DataProximaVisita
    : '-'

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  } else {
    produtor.src = relatorio.AssinaturaResponsavel
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor

  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    doc.text(
      relatorio.NomeResponsavel,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura do responsável',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      var cursor = 0
      header(doc, config, data)
      subHeader(doc, data, config)
      config.startY = config.startY + config.boxHeight * 4 + 40
      indicadoresQualidade(doc, data, config)
      config.startY = cursor + config.boxHeight + 10
      cursor = melhoriaLeite(doc, data, config, cursor)
      config.startY = cursor + config.boxHeight - 3
      cursor = limpezaOrdenha(doc, data, config, cursor)
      config.startY = cursor + config.boxHeight - 3
      cursor = limpezaTanque(doc, data, config, cursor)
      config.startY = cursor + config.boxHeight - 3
      cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
      const pageCount = doc.internal.getNumberOfPages()

      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        rodapeImpressao(
          doc,
          config,
          cursor + config.boxHeight,
          String(i) + ' de ' + String(pageCount),
        )
      }
      doc
        .save(
          `relatorio-bonolat-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
          { returnPromise: true },
        )
        .then(() => {
          return resolve()
        })
    } catch (err) {
      reject(err)
    }
  })
}
